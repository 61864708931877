import * as React from "react"
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { BlockRenderer, Layout, TrilingualString } from '@components'
import { Whitespace, Headline } from './privacy-policy'


interface LegalPageProps {
  data: {
    sanityLegalPage: GatsbyTypes.SanityLegalPage
  }
}

const TermsPage = ({ data: { sanityLegalPage: {
  title,
  _rawLocalizedTitle,
  _rawCopy,
} } }: LegalPageProps) => (
  <Layout>
    <Helmet>
      <title>Bawi Agua Fresca: {title}</title>
    </Helmet>
    <Whitespace>
      <Headline><TrilingualString>{_rawLocalizedTitle}</TrilingualString></Headline>
      {_rawCopy && (
        <BlockRenderer>{_rawCopy!}</BlockRenderer>
      )}
    </Whitespace>
  </Layout>
)


export const query = graphql`
  query TermsPageQuery {
    sanityLegalPage(slug: {current: {eq: "terms-and-conditions"}}) {
      ...legalPageFields
    }
  }
`


export default TermsPage
